<template>
  <div class="page redirect-ticket-list-page">
    <template v-if="loading">
      <div v-if="loading" class="loading">
        <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
        <p class="loading__text">QR코드를 분석하는 중입니다</p>
      </div>
    </template>
    <template v-else>
      <div class="page-close">
        <button
          v-button
          type="button"
          @click="$router.replace({ name: 'Home' })"
          class="page-close__button"
        >
          <img src="/img/icon/close.svg" />
        </button>
      </div>
      <div class="page-header">
        <h1 class="page-header__text">
          <strong>체크인·체크아웃</strong>을 진행할 티켓을 선택해주세요
        </h1>
      </div>
      <div class="page-content">
        <ul class="ticket-list">
          <li
            v-for="ticket in ticketList"
            :key="ticket.ticket_code"
            class="ticket-list__item"
          >
            <ui-ticket :ticket="ticket" @click="evokeTicketAction(ticket)" />
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import TicketItem from "@/components/resources/ticket/TicketItem";
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";

export default {
  data() {
    return {
      storeId: this.$route.query.store_id,

      lottieOptions: {
        animationData: LottieLoading,
      },
      loading: false,
    };
  },

  computed: {
    ticketList() {
      return this.$store.getters["tickets/GET_ITEMS_BY_LIST"]({
        list: "MyTickets_available",
      });
    },
  },

  async created() {
    if (!this.storeId) {
      this.$router.replace("/");
      return;
    }

    this.loading = true;
    this.loadTickets()
      .then(() => {
        if (this.ticketList.length === 1) {
          return this.evokeTicketAction(this.ticketList[0]);
        }

        if (this.ticketList.length < 1) {
          return this.$router.replace({
            name: "Stores",
            query: { autoSearch: "target", sid: this.storeId },
          });
        }
      })
      .catch((e) => {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    async loadTickets() {
      if (this.ticketList.length > 0) return;

      await this.$store.dispatch("tickets/getList", {
        list: "MyTickets_available",
        filter: "available",
        query: { page: 1, size: 50 },
      });
    },

    evokeTicketAction(ticket) {
      this.$router.replace({
        name: "TicketSingle",
        params: { id: ticket.ticket_code },
        query: {
          from: this.$data.from,
          action: ticket.status === "ISSUED" ? "CHECK_IN" : "CHECK_OUT",
          method: "qr_code",
        },
      });
    },
  },

  components: {
    UiTicket: TicketItem,
    Lottie,
  },
};
</script>

<style lang="scss" scoped>
.page {
  flex: 1;
  @include fill-viewport-min-height;
  background: $color-white;

  .loading {
    width: 100%;
    padding: unit(30);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__text {
      color: #a9afb3;
      font-size: unit(14);
      text-align: center;
      line-height: 1.43;
      font-weight: 500;
    }
  }

  .page-close {
    height: unit(56);

    &__button {
      width: unit(56);
      height: unit(56);

      img {
        margin: auto;
        width: unit(24);
        height: unit(24);
        object-fit: contain;
      }
    }
  }

  .page-header {
    padding: unit(24) unit(16);

    &__text {
      font-size: unit(22);
      font-weight: 500;
      line-height: 1.5;

      strong {
        font-weight: bold;
      }
    }
  }

  .ticket-list {
    padding: unit(24) unit(16);

    &__item {
      + * {
        margin-top: unit(12);
      }
    }
  }
}
</style>
